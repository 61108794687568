._structGPTDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

._structGPTMainLabel {
    font-size: 24px;
    font-weight: 500;
}
