.trussAnalysisCanvas {
    height: 100%;
    width: 75%;
    background-color: white;
}

.informationTable {
    width: 100%;
    border-collapse: collapse;
    color: #F5F5F5;
    margin-top: 10px;
    font-size: 14px;
}

.informationTable th {
    border: 1px solid #F5F5F5;
    padding: 8px;
    text-align: left;
}

.informationTable td {
    border: 1px solid #F5F5F5;
    padding: 8px;
    text-align: left;
}