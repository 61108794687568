.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 400px;
    margin: auto;
}

.signup-form {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group {
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
}

.input-group input {
    width: 350px;
    height: 18px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.btn {
    width: 300px;
    padding: 10px 15px;
    margin: 10px 0;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary {
    background-color: black;
    color: white;
}

.btn-primary:hover {
    color: darkgrey;
    scale: 1.03;
}

.btn-secondary {
    background-color: #F5F5F5;
    color: black;
}

.btn-secondary:hover {
    background-color: lightgrey;
    scale: 1.03;
}

.btn-google {
    background-color: #F5F5F5;
    color: black;
}

.btn-google:hover {
    background-color: lightgrey;
    scale: 1.03;
}

.error-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
}

.error-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-popup p {
    margin-bottom: 15px;
    color: red;
    font-weight: bold;
}

.btn-close {
    background-color: #ff4444;
    color: white;
}

.btn-close:hover {
    background-color: #cc0000;
}

.alternative-signin {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.google-logo {
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    margin-right: 10px; /* Space between the logo and the text */
    vertical-align: middle; /* Align the logo with the text */
}

