._button {
    background-color: black;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: white;
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
}

._button:hover {
    color: lightgrey;
    scale: 1.03;
}

._articleDiv {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

._articleHeading {
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    margin-left: 10px;
}

._articleDescription {
    font-size: 16px;
    color: darkgrey;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    margin-left: 10px;
}

._articleContentTitle {
    width: 60%;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
}

._articleContentHeading {
    width: 60%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

._articleContentSubHeading {
    width: 60%;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

html {
    scroll-behavior: smooth;
}

._listContainer {
    width: 100%;
    padding: 10px;
}

li {
    padding-top: 5px;
    padding-bottom: 5px;
}

ol {
    margin: 0;
}