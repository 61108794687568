._informationPageContentBlock {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 20px;
}

._informationPageContentHeading {
    font-size: 16px;
    font-weight: 500;
}

._informationPageContent {
    font-size: 16px;
    margin-top: 20px;
}

._informationPageContentMainHeading {
    font-size: 24px;
    font-weight: 500;
}
