._settingsOverlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1;
}

._settingsDiv {
    height: auto;
    width: 450px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

._settingsTitleDiv {
    background-color: #323439;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

._settingsTitleHeading {
    flex: 1;
    margin: 0px;
    padding: 0px;
    font-size: 22px;
    font-weight: 500;
}

._settingsTitleCloseButton {
    width: auto;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    cursor: pointer;
}

._designStandardDiv {
    display: flex;
    padding: 15px;
    padding-top: 20px;
}

._designStandardLabel {
    width: 200px;
}

._designStandardOption {
    flex: 1;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: right;
    margin-left: 20px;
}

._unitsDiv {
    display: flex;
    margin-bottom: 30px;
}

._unitsLabel {
    width: 200px;
    display: flex;
    align-items: center;
}

._allowableDCRDiv {
    display: flex;
    padding: 15px;
}

._allowableDCRLabel {
    width: 200px;
}

._allowableDCRInput {
    flex: 1;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: right;
    margin-left: 20px;
}

._designedByDiv {
    display: flex;
    padding: 15px;
}

._designedByLabel {
    width: 200px;
}

._designedByInput {
    flex: 1;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: right;
    margin-left: 20px;
}

._checkedByDiv {
    display: flex;
    padding: 15px;
    padding-bottom: 20px;
}

._checkedByLabel {
    width: 200px;
}

._checkedByInput {
    flex: 1;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: right;
    margin-left: 20px;
}

._doneButtonDiv {
    display: flex;
    padding: 10px;
    padding-right: 15px;
    background-color: #323439;
    color: white;
    height: 32px;
}

._doneButton {
    width: auto;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    cursor: pointer;
}
