._containerDiv {
    display: flex;
    height: 100vh;
    flex-direction: row;
    overflow: hidden;
}

._designInputDiv {
    width: 25%;
    height: 100%;
    background-color: #323439;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

._designDashboardDiv {
    width: 75%;
    height: 100%;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
}

._designInputsTitleBar {
    height: 30px;
    width: 100%;
    padding: 15px;
}

._designInputs {
    padding: 15px;
    padding-top: 0;
    overflow-y: auto;
}

._dashboardTitleBar {
    height: 30px;
    width: 100%;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

._dashboardHeading {
    color: white;
    font-size: 24px;
    font-weight: 500;
}

._dashboardButtons {
    margin-right: 20px;
}

._dashboardTiles {
    display: flex;
    flex-direction: row;
    height: calc(100% - 85px);
}

._dashboardSection {
    display: flex;
    flex-direction: column;
    width: 50%;
}

._dashboardTile {
    background-color: white;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: calc(50% - 20px);
}

._tileHeading {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    padding-left: 15px;
    background-color: #323439bd;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: center;
}

._dashboardTileTitleBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #323439bd;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    align-items: center;
}

._dashboardTileTitleBarDropdown {
    width: 250px;
    margin: 5px;
    margin-right: 5px;
    height: 25px;
    background-color: white;
    border: 1px solid white;
    border-radius: 5px;
}

._tileContentDiv {
    flex: 1;
    margin: 10px;
    font-size: 12px;
    overflow-y: auto;
    height: 50%;
}

._dashboardStatusBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #1A1A1A;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    height: 20px;
    align-items: center;
}

.diagramSVG {
    height: 100%;
    width: 100%;
    font-size: 12px;
}

/*Truss analysis styling*/

._drawingCanvasDiv {
    display: flex;
    flex-direction: row;
    height: calc(100% - 85px);
    padding: 10px;
}

._nodesDiv {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

._elementsDiv {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

._inputDivButton {
    margin: 10px;
    padding: 10px;
    background: white;
    width: 100px;
    border-radius: 2px;
    border: none;
}

._inputDivButton:disabled {
    margin: 10px;
    padding: 10px;
    background: darkgrey;
    width: 100px;
    border-radius: 2px;
    border: none;
}

._inputDivButton:hover:disabled {
    scale: 1;
    cursor: default;
}

._inputDivButton:hover:enabled {
    cursor: pointer;
    scale: 1.03;
}

._analysisResultsDiv {
    width: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

._analysisResultsTable {
    color: black;
    border-collapse: collapse;
}

._analysisResultsTable th {
    border: 1px solid black;
    padding: 2px;
}

._analysisResultsTable td {
    border: 1px solid black;
    padding: 2px;
}
