._threeLineItemDiv {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
}

._threeLineItemItem1 {
    width: 50%;
}

._threeLineItemItem2 {
    width: 25%;
    display: flex;
    justify-content: center;
}

._threeLineItemItem3 {
    width: 25%;
    display: flex;
    justify-content: end;
}

._table1ContainerDiv {
    flex-direction: column;
    width: 100%;
    display: flex;
}
