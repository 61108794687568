._titleBarDiv {
    display: flex;
    justify-content: space-between;
    background-color: white;
    height: 60px;
}

._startSectionDivV2 {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

._titleBarCompanyLogo {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

._titleBarHeading {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
}

._titleBarLink {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
}

._middleSectionDivV2 {
    display: flex;
    align-items: center;
}

._endSectionDivV2 {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
