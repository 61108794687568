._designToolsHeadingDiv {
    margin: 20px;
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

._productsDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
}

._filterDiv {
    width: 20%;
    margin-left: 20px;
}

._productTilesDiv {
    display: grid;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    width: 100%;
}

._productTilesSearchField {
    padding: 5px;
    width: 15%;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
}

@media (min-width: 901px) and (max-width: 1200px) {
    ._productTilesDiv {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1201px) and (max-width: 1500px) {
    ._productTilesDiv {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1501px) and (max-width: 1800px) {
    ._productTilesDiv {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1801px) and (max-width: 2400px) {
    ._productTilesDiv {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (min-width: 2401px) and (max-width: 3000px) {
    ._productTilesDiv {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (min-width: 3001px) and (max-width: 3600px) {
    ._productTilesDiv {
        grid-template-columns: repeat(7, 1fr);
    }
}