.expandable-section {
    margin-bottom: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: white;
}

.section-header {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif !important;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 500 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

._expandableSectionImage {
    height: 20px;
    width: 20px;
}

._expandableSectionHeading {
    margin-left: 10px;
}

.section-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s ease-out;
}

.section-content.expanded {
    max-height: 1000px; /* Adjust this value according to your content */
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.section-content.collapsed {
    max-height: 0;
}
