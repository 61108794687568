/* Container for menu items */
._menuItemsDiv {
    margin-left: 20px;
    width: 20%;
}

/* Style for individual menu items */
.menu-item {
    display: block;
    font-size: 16px;
    font-weight: 500;
    width: 100%; /* Ensure full width within container */
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid transparent; /* Subtle border for better clickability */
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s, font-weight 0.3s, transform 0.2s; /* Smooth transitions */
}

/* Active state for menu items */
.menu-item.active {
    background-color: #E8E8E8; /* Slightly darker for active */
    font-weight: 700;
    border-color: #DADADA; /* Subtle border to denote active state */
}

/* Hover effect for menu items */
.menu-item:hover {
    background-color: #F0F0F0; /* Light background for hover */
    transform: scale(1.02); /* Slight scale effect */
}

/* Styles for content items */
.content-item {
    display: none;
}

.content-item.active {
    display: flex;
    flex-direction: row;
    flex: 1;
}

/* Styles for feedback form line items */
.feedbackFormLineItem {
    margin: 5px 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center; /* Align items center for consistency */
}

/* Submit button styling */
.feedbackFormSubmitButton {
    background-color: #333; /* Darker background for a modern look */
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: white;
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 5px; /* Slightly rounded corners for modern appeal */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
}

/* Hover effect for submit button */
.feedbackFormSubmitButton:hover {
    background-color: #555; /* Slightly lighter on hover */
    transform: scale(1.02);
}

/* Labels in feedback form */
.feedbackFormLabels {
    font-size: 16px;
    width: 120px; /* Slightly wider for readability */
    font-weight: 500;
}

/* Input field styling */
.feedbackFormInputField {
    flex: 1;
    padding: 8px; /* Slightly increased padding */
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

/* Hover and focus effects for input fields */
.feedbackFormInputField:hover {
    border-color: #999;
}

.feedbackFormInputField:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.3); /* Subtle box shadow */
}

/* Textarea styling */
.feedbackFormTextArea {
    height: 300px;
    width: 100%; /* Full width */
    resize: none;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

/* Hover and focus effects for textarea */
.feedbackFormTextArea:hover {
    border-color: #999;
}

.feedbackFormTextArea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}

/* User profile container styling */
.user-profile {
    max-width: 500px; /* Slightly wider for better layout */
    margin: 20px auto;
    padding: 20px;
    background-color: #F9F9F9; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Light shadow for elevation */
}

/* User info styling */
.user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Increased space for better separation */
}

/* User info label and value styling */
.user-info label {
    font-weight: 600; /* Slightly bolder */
    margin-right: 10px;
    width: 120px;
    text-align: left;
}

.user-info span {
    flex-grow: 1;
    width: 200px; /* Adjusted width */
}

/* Editable button styling */
.editable button {
    margin-left: 10px;
    padding: 6px 12px; /* Slightly larger padding */
    font-size: 16px;
    cursor: pointer;
    background-color: #FFF;
    border: 1px solid #DDD; /* Subtle border */
    color: #333;
    border-radius: 4px; /* Rounded corners */
    transition: color 0.3s, transform 0.2s;
}

/* Hover effect for editable button */
.editable button:hover {
    color: #555;
    background-color: #F5F5F5;
    transform: scale(1.02);
}

/* Settings container styling */
.settings {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Setting item styling */
.setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; /* Adjusted margin */
}

/* Setting item label styling */
.setting-item label {
    font-weight: 600;
    margin-right: 10px;
    width: 200px;
}

/* Toggle switch styling */
.toggle-switch {
    position: relative;
    width: 60px; /* Slightly wider for better aesthetics */
    height: 30px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    transition: 0.4s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* Styles for checked state */
input:checked + .slider {
    background-color: #007bff;
}

input:checked + .slider:before {
    transform: translateX(30px);
}

/* General container for subscription */
.subscription-container {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background-color: #F9F9F9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Info section styling */
.subscription-info {
    margin-bottom: 20px;
}

/* Pay button styling */
.pay-button {
    background-color: #333;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: white;
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s;
}

.pay-button:hover {
    background-color: #555;
    transform: scale(1.02);
}

/* Note styling */
.note {
    font-size: 14px;
    color: #666;
}

.strikethrough {
    text-decoration: line-through;
    color: #888; /* Optional: Change text color for better visibility */
    margin-right: 5px; /* Space between strikethrough amount and current amount */
}

/* Current amount styling */
.current-amount {
    color: white; /* Color for the current amount */
    font-weight: 700; /* Optional: Make it bold */
}
