._productTileDiv {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px;
    background-color: #f5f5f5;
    margin: 20px;
}

._productTileHeading {
    font-size: 18px;
    margin-bottom: 10px;
    height: 55px;
}

._productTileAccessStatus {
    font-size: 16px;
    margin-bottom: 10px;
    height: 25px;
}

._productTileDescription {
    font-size: 16px;
    color: darkgrey;
    margin-bottom: 10px;
    height: 155px;
}

._productTileLinkButton {
    background-color: black;
    border: none;
    cursor: pointer;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
    font-size: 16px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
    margin-top: 20px;
}

._productTileLinkButton:hover {
    scale: 1.03;
}
