._sectionFooterDiv {
    background-color: #333333;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

._startSectionDiv {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 20px;
    font-size: 16px;
}

._middleSectionDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

._endSectionDiv {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 20px;
}
