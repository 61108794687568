._twoLineItemDiv {
    display: flex;
    margin-top: 6px;
    margin-bottom: 6px;
}

._twoLineItemItem1 {
    width: 70%;
}

._twoLineItemItem2 {
    flex: 1;
    display: flex;
    justify-content: end;
}

._table3ContainerDiv {
    flex-direction: column;
    display: flex;
}
