/*section 1 styling*/
._sectionDivV1 {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 500px;
}

._sectionInnerDivV1 {
    display: flex;
    flex-direction: row;
}

._leftSectionDivV1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    opacity: 0; /* Ensure divs are hidden initially */
    transition: opacity 0.5s ease-out; /* Smooth transition for opacity */
}

._leftSectionInnerDivV1 {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 20px;
    max-width: 70%;
}

._leftSectionTitleV1 {
    margin: 10px;
    font-size: 32px;
    padding: 0;
}

._leftSectionContentV1 {
    margin: 10px;
    font-size: 20px;
    padding: 0;
}

._leftSectionButtonV1 {
    background-color: black;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: white;
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
}

._leftSectionButtonV1:hover {
    color: lightgrey;
    scale: 1.03;
}

._rightSectionDivV1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    opacity: 0; /* Ensure divs are hidden initially */
    transition: opacity 0.5s ease-out; /* Smooth transition for opacity */
}

._rightSectionImageV1 {
    scale: 0.9;
}

/*section 2 styling*/
._sectionDivV2 {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    position: relative;
    height: 510px;
}

._sectionTitleV2 {
    font-size: 20px;
    font-weight: 500;
    margin: 10px;
    z-index: 2;
    background-color: transparent;
}

._sectionDescriptionV2 {
    font-size: 16px;
    z-index: 2;
    background-color: transparent;
}

._sectionContentV2 {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    flex: 1;
    z-index: 2;
    background-color: transparent;
}

._contentBlockDivV2 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 20px;
    background-color: #F5F5F5;
    flex: 1;
    border-radius: 5px;
    z-index: 2;
    opacity: 0;
    transform: translateY(-30px);
    animation: drop-in 0.5s forwards;
    animation-play-state: paused; /* Pause the animation initially */
}

._contentBlockDivV2.block1 {
    animation-delay: 0s;
}

._contentBlockDivV2.block2 {
    animation-delay: 0.125s;
}

._contentBlockDivV2.block3 {
    animation-delay: 0.25s;
}

._contentBlockDivV2.block4 {
    animation-delay: 0.375s;
}

/* Keyframes for the drop-in animation */
@keyframes drop-in {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

._contentBlockTitleV2 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    z-index: 2;
}

._sectionDivV2BackgroundAnimationCanvas {
    height: 100%;
    width: 100%;
    object-fit: none;
    background-color: white;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 1600px) {
    ._sectionContentV2 {
        max-width: 90%;
    }
    ._contentBlockDivV2 {
        max-width: 20%;
    }
}

@media (min-width: 1601px) and (max-width: 2000px) {
    ._sectionContentV2 {
        max-width: 80%;
    }
    ._contentBlockDivV2 {
        max-width: 18%;
    }
}

@media (min-width: 2001px) and (max-width: 2400px) {
    ._sectionContentV2 {
        max-width: 70%;
    }
    ._contentBlockDivV2 {
        max-width: 16%;
    }
}

@media (min-width: 2401px) and (max-width: 2800px) {
    ._sectionInnerDivV1 {
        max-width: 80%;
    }
    ._sectionContentV2 {
        max-width: 60%;
    }
    ._contentBlockDivV2 {
        max-width: 16%;
    }
}

/*Section 3*/
._sectionDivV3 {
    display: flex;
    background-color: #F5F5F5;
    padding-top: 100px;
    padding-bottom: 100px;
    height: 250px;
}

._contentBlockDivV3 {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #F5F5F5;
    justify-content: center;
    align-items: start;
    padding-left: 10%;
    padding-right: 10%;
    opacity: 0; /* Ensure divs are hidden initially */
    transition: opacity 0.5s ease-out; /* Smooth transition for opacity */
}

._contentBlockTitleV3 {
    font-size: 20px;
    margin: 10px;
    font-weight: 500;
}

._contentBlockContentV3 {
    font-size: 16px;
    margin: 10px;
}

._contentBlockButtonV3 {
    background-color: black;
    border: none;
    cursor: pointer;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
    font-size: 16px;
    color: white;
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2px;
}

._contentBlockButtonV3:hover {
    color: lightgrey;
    scale: 1.03;
}

/*section 4*/

._sectionV4 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 20px
}

._founderInformationSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-left: 100px;
    padding-right: 100px;
    align-items: left;
    opacity: 0; /* Ensure divs are hidden initially */
    transition: opacity 0.5s ease-out; /* Smooth transition for opacity */
}

._founderSectionImage {
    height: 200px;
    width: 200px;
    margin-bottom: 20px;
    border-radius: 100px;
}

._founderName {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

/* Animation keyframes */
@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Apply animations */
._leftSectionDivV1 {
    animation: slideInLeft 0.5s ease-in-out forwards;
}

._rightSectionDivV1 {
    animation: slideInRight 0.5s ease-in-out forwards;
}

/* Apply the animations when the divs are in view */
._contentBlockDivV3.animate-left {
    animation: slideInLeft 0.5s ease-in-out forwards;
}

._contentBlockDivV3.animate-right {
    animation: slideInRight 0.5s ease-in-out forwards;
}

/* Apply the animations when the divs are in view */
._founderInformationSection.animate-left {
    animation: slideInLeft 0.5s ease-in-out forwards;
}

._founderInformationSection.animate-right {
    animation: slideInRight 0.5s ease-in-out forwards;
}

._beamAnalysisImage {
    scale: 0.3;
}
