._inputDataEntryDiv {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    color: white;
    font-size: 14px;
}

._inputDataEntryTitle {
    width: 50%;
}

._inputDataEntryInput {
    flex: 1;
    padding: 2px;
    font-size: 14px; /* Adjust font size as needed */
    text-align: right;
    min-width: 150px;
    border: 1px solid #ccc; /* Border color */
    border-radius: 5px; /* Rounded corners */
    padding: 5px 0; /* Adjust padding as needed */
}

._inputDataEntryInput:hover {
    border-color: #666;
}

._inputDataEntryInput:focus {
    outline: none;
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 2px rgba(0, 123, 255, 0.5); /* Add a subtle box shadow */
}

._inputDataEntryUnit {
    display: flex;
    width: 12%;
    margin-left: 5px;
    align-items: center;
}

._inputHeadingDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

._inputHeading {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: white;
}

._inputHeadingButton {
    display: inline-flex; /* Aligns child elements (like text) in the center */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    width: 16px; /* Adjust the width as needed */
    height: 16px; /* Adjust the height as needed */
    border-radius: 50%; /* This makes the button round */
    border: 1.5px solid white; /* Adjust the border thickness and color as needed */
    font-size: 10px; /* Adjust the size of the "i" inside the button */
    cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
    background-color: transparent; /* Sets a transparent background */
    margin-left: 10px;
    margin-top: 3.5px;
    color: white;
}

._inputHeadingButton:focus {
    outline: none; /* Removes the outline on focus for a cleaner look */
}

._inputHeadingButton:hover {
    background-color: grey; /* Optional: Changes background on hover for visual feedback */
}
