._informationOverlayDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

._informationOverlayInnerDiv {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 5px;
}

._informationOverlayHeadingDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #323439;
}

._informationOverlayHeading {
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    padding: 10px;
    background-color: #323439;
    color: white;
}

._informationOverlayImageDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
}

._informationOverlayNavigationButtons {
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 10px;
}

._informationOverlayImage {
    width: 500px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

._informationOverlayCaption {
    font-size: 18px;
    text-align: center;
    padding: 10px;
    background-color: #323439;
    color: white;
}
