/*Title Bar Styling*/
body {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

._horizontalDividerLine {
    margin: 20px;
    background-color: black;
    border: none;
    height: 0.5px;
}

/* Hide default scrollbars */
::-webkit-scrollbar {
    display: none;
}

/* Style for scrollbar track */
::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Change track color */
}

/* Style for scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #888; /* Change thumb color */
    border-radius: 10px; /* Round the thumb */
}

/* Style for scrollbar corner */
::-webkit-scrollbar-corner {
    background-color: transparent; /* Change corner color */
}

.error-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.error-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.profilePageButtons {
    font-size: 16px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
}

.profilePageButtons:hover {
    scale: 1.03;
}

/* expandable mui section */
.MuiAccordionSummary-content.Mui-expanded,
.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded,
.MuiAccordionSummary-content,
.css-namekp.Mui-expanded,
.css-1bqr8xr.Mui-expanded,
.MuiPaper-root-MuiAccordion-root,
.MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
    min-height: 0 !important;
    border-bottom: none !important;
}

.css-1bqr8xr.Mui-expanded,
.MuiAccordionSummary-content {
    margin-bottom: 10px !important;
}

.MuiAccordionDetails-root {
    padding-top: 0 !important;
}

.MuiPaper-root {
    margin-bottom: 5px;
}

.MuiPaper-root::before {
    content: none !important;
}

.MuiPaper-root-MuiAccordion-root {
    position: inherit !important;
}
